<template>
  <div class="projectManage">
    <div class="manage-tab">
      <ul>
        <li
          :class="{ active: activeIndex == index }"
          v-for="(item, index) in friendType"
          :key="item.fid"
          @click="active(index)"
        >
          {{ item.type }}
        </li>
      </ul>
      <div>
        <span @click="btnRouter">*新しいプロジェクト</span>
      </div>
    </div>
    <div class="form-box">
      <el-input placeholder="入力して下さい" v-model="searchFriend">
        <template slot="append">
          <el-button type="danger" @click="serachProject()"
            >検索</el-button
          ></template
        >
      </el-input>
    </div>
    <div v-if="activeIndex === 0">
      <div class="table-box">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="nickname" label="ユーザ名" width="100">
          </el-table-column>
          <el-table-column prop="title" label="漫画タイトル"></el-table-column>
          <el-table-column prop="updated_at" label="最終更新">
          </el-table-column>
          <!-- 修改登录信息 -->
          <el-table-column prop="id" label="登録情報修正">
            <template scope="scope">
              <el-button
                @click="modifyProjectInfo(scope.row)"
                type="text"
                icon="el-icon-s-tools"
                size="small"
                >修正</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="page-box">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="table-box">
        <el-table :data="table_Data" border style="width: 100%">
          <el-table-column prop="nickname" label="原作者" width="100">
          </el-table-column>
          <el-table-column prop="title" label="漫画タイトル"> </el-table-column>
          <el-table-column prop="updated_at" label="最終更新">
          </el-table-column>
        </el-table>
        <div class="page-box">
          <el-pagination
            @size-change="handleSizeChange_"
            @current-change="handleCurrentChange_"
            :current-page="currentPage_"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from "@/service";
export default {
  data() {
    return {
      friendType: [
        {
          fid: 1,
          type: "プロジェクト",
        },
        {
          fid: 2,
          type: "参加したプロジェクト",
        },
      ],
      activeIndex: 0,
      searchFriend: "",
      tableData: [],
      table_Data: [],
      currentPage: 1,
      currentPage_: 1,
      count: 5,
    };
  },
  mounted() {
    this.getProjectList();
    this.getPartProList();
  },
  methods: {
    serachProject() {
      if (this.activeIndex == 0) {
        let params = {
          p: 1,
          keyword: this.searchFriend,
        };
        this.getProjectList(params);
      } else {
        let params = {
          p: 1,
          keyword: this.searchFriend,
        };
        this.getPartProList(params);
      }
    },
    active(i) {
      this.activeIndex = i;
    },
    btnRouter() {
      this.$router.push({
        path: "personal-index/creat-project",
        query: { id: 6 },
      });
    },
    getProjectList(data) {
      service.personal
        .getProjectList(data)
        .then((res) => {
          if (res.status === 200) {
            this.tableData = res.data;
          }
        })
        .catch((err) => {});
    },
    getPartProList(data) {
      service.personal
        .getPartProList(data)
        .then((res) => {
          if (res.status === 200) {
            this.table_Data = res.data;
          }
        })
        .catch((err) => {});
    },
    modifyProjectInfo(row) {
      this.$router.push({
        path: "/personal-center/personal-index/creat-project",
        query: {
          id: 0,
          params: JSON.stringify(row),
        },
      });
    },
    handleSizeChange(val) {
      console.log(`頁 ${val} 条`);
    },
    handleSizeChange_(val) {
      console.log(`頁 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当頁: ${val}`);
      this.getProjectList({ p: val });
    },
    handleCurrentChange_(val) {
      console.log(`当頁: ${val}`);
      this.getPartProList({ p: val });
    },
  },
};
</script>
<style lang="scss" scoped>
.projectManage {
  .page-box {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  width: 100%;
  .manage-tab {
    display: flex;
    width: 100%;
    ul {
      display: flex;
      flex: 1;
      align-items: center;
      li {
        padding: 15px;
        cursor: pointer;
        font-size: 18px;
      }
      li.active {
        color: #f03747;
      }
    }
    > div {
      //   width: 10%;
      padding: 15px;
      > span {
        display: block;
        width: 160px;
        height: 50px;
        font-size: 18px;
        line-height: 50px;
        text-align: center;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #f03747;
        color: #f03747;
        font-weight: bold;
        cursor: pointer;
        letter-spacing: 1px;
      }
    }
  }
  .form-box {
    padding: 0 15px;
    margin-top: 10px;
  }
  .table-box {
    padding: 0 15px;
    margin-top: 20px;
    .style {
      cursor: pointer;
      color: #1875d2;
    }
  }
}
</style>
